<template>
  <div class="layout">
    <BannerMainLayout
      v-if="slider"
      :image-id="slider.imageId"
      :caption="slider.caption"
    />
    <!--    <Breadcrumbs-->
    <!--      v-if="route.fullPath !== '/'"-->
    <!--      :breadcrumbs="breadcrumbs"-->
    <!--    />-->
    <layout-placeholder
      :blocks="blocks"
      :external-data="$attrs?.externalData"
      :breadcrumbs="breadcrumbs"
    />
  </div>
</template>

<script setup lang="ts">
import type { IBreadcrumbs } from '~/components/UI/Breadcrumbs.vue';
import BannerMainLayout from '~/components/common/BannerMainLayout/BannerMainLayout.vue';
import type { IContentBlockCms } from '#sitis/internal/controllers/cms/models/Content';
import { useRoute } from '#app';

const { $api, ssrContext } = useNuxtApp();

const route = useRoute();
const router = useRouter();
const url = useRequestURL();
const canonical = 'https://' + url.hostname + route.path

useHead({
  meta: [
    {
      name: 'canonical',
      content: canonical,
    },
    {
      property: 'og:url',
      content: canonical,
    },
  ],
})
type SliderTypeValue = {
	imageId: string,
	caption: string
}
type SliderType = {
	value: SliderTypeValue[]
}

const { breadcrumbs, properties } = defineProps<{
	blocks: IContentBlockCms [];
	externalData?: any;
	breadcrumbs: IBreadcrumbs[];
	properties: {
		slider: SliderType
	};
}>();

const slider = computed(() => properties?.slider?.value[0]);

</script>
